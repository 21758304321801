/* General body styles */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body * {
  font-family: "Arial";
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* App.css or index.css */
body,
html {
  margin: 0;
  padding: 0;
  height: 100%;
  background-color: #f0f2f5; /* Your desired background color */
}

.app-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #f0f2f5; /* Ensure all pages have the same background color */
}

/* Navbar styles */
.navbar {
  display: flex;
  align-items: center;
  background-color: #001529;
  padding: 0 20px;
  justify-content: space-between;
}

.navbar .logo {
  max-width: auto;
  height: 50px;
}

.navbar .logo img {
  height: 50px;
}
.logo-text {
  font-family: 'Montserrat', sans-serif;
  font-size: 44px; /* Adjust as needed */
  font-weight: bold;
  margin-left: 10px; /* Space between logo and text */
  background: linear-gradient(150deg, #efc73c, #bb9638); /* Gradient from #efc73c to #bb9638 */
  -webkit-background-clip: text; /* For Safari and older WebKit browsers */
  -moz-background-clip: text; /* For Firefox (if applicable) */
  background-clip: text; /* Standard property */
  color: transparent; /* Make the text color transparent to show the gradient */
}



.navbar .menu {
  flex: 1;
  display: flex;
  justify-content: center;
}

.navbar .menu .ant-menu-item {
  font-family: "Arial";
  font-size: 25px;
}

/* Change hover background color */
.navbar .menu .ant-menu-item:hover {
  background-color: #d1bd21; /* Set your desired hover background color */
}

/* Change selected background color */
.navbar .menu .ant-menu-item.ant-menu-item-selected {
  background-color: #d1bd21; /* Set the same background color as hover color */
  color: #fff; /* Optional: Change text color to white or any other color to match your design */
}

.navbar .book-demo-button {
  margin-left: auto;
  background-color: #d1bd21;
  color: black;
}

.navbar .mobile-menu-icon {
  display: none;
  font-size: 24px;
  cursor: pointer;
}


@media (max-width: 1024px) {
  .navbar {
    padding: 0 15px; /* Slightly more padding for larger tablets */
  }

  .navbar .logo img {
    height: 45px;
  }

  .navbar .menu {
    display: none; /* Hide menu for smaller screens */
  }

  .navbar .mobile-menu-icon {
    display: block;
    font-size: 28px; /* Slightly larger for easy tap */
    margin-left: auto;
    color: #d1bd21;
    cursor: pointer;
  }

  .navbar .book-demo-button {
    display: none;
  }
}

@media (max-width: 768px) {
  .navbar {
    padding: 0 10px;
  }

  .navbar .logo img {
    height: 40px; /* Reduce logo size for smaller screens */
  }

  .navbar .mobile-menu-icon {
    font-size: 24px;
  }
}

@media (max-width: 480px) {
  .navbar {
    padding: 0 5px;
  }

  .navbar .logo img {
    height: 35px; /* Adjust for smaller phones */
  }

  .navbar .mobile-menu-icon {
    font-size: 22px;
  }
}


.hero-section {
  background-color: #f0f2f5;
  padding: 40px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center; /* Ensure text inside is centered */
  min-height: 90vh;
}

.hero-container {
  max-width: 1400px;
  width: 100%;
  display: flex;
  flex-direction: column; /* Stack elements vertically */
  align-items: center; /* Center items horizontally */
  justify-content: center; /* Center items vertically */
}

.hero-text {
  text-align: center; /* Center text inside the container */
}

.hero-title {
  font-size: 72px;
  font-weight: bold;
  color: #000;
}

.hero-description {
  font-size: 20px;
  color: #555;
  max-width: 800px; /* Adjust width for better readability */
  margin: 0 auto; /* Center align the block */
}

@keyframes waveAnimation {
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-8px); }
}

.wave {
  display: inline-block;
  animation: waveAnimation 1.5s infinite;
  color: #ff5733; /* Pick a standout color */
}


p.terms {
  font-size: 14px; /* Adjust font size */
  margin-top: px; /* Decrease space between the button and the terms */
  text-align: center;
}

p.terms a {
  color: #007bff; /* Inherit text color */
  text-decoration: none; /* Add underline to links */
}

p.terms a:hover {
  color: #d1bd21; /* Change link color on hover */
}

/* Button styles */
.button {
  display: inline-block;
  padding: 10px 20px;
  margin: 10px;
  font-size: 16px;
  cursor: pointer;
  text-decoration: none;
  border: 2px solid #007bff;
  border-radius: 5px;
  color: #d1bd21;
  background-color: #fff;
  transition:
    background-color 0.3s,
    color 0.3s;
}

.button:hover {
  background-color: #d1bd21;
  color: #fff;
}

.button:focus,
.button:active {
  background-color: #d1bd21 !important;
  color: #fff !important;
  border-color: #d1bd21 !important;
}

.ant-btn-primary {
  background-color: #d1bd21 !important;
  border-color: #d1bd21 !important;
  color: black !important;
}

.ant-btn-primary:hover,
.ant-btn-primary:focus,
.ant-btn-primary:active {
  background-color: #d1bd21 !important;
  border-color: #d1bd21 !important;
  color: black !important;
}

.ant-menu-item-selected {
  background-color: #d1bd21 !important;
  color: black !important;
}
.button-container {
  display: flex;
  justify-content: center; /* Centers the button horizontally */
  align-items: center; /* Centers the button vertically */
  padding: 20px; /* Add padding around the container */
  height: 100%; /* Ensures the container takes up full height */
  margin-top: 20px;
}

.sign-up {
  padding: 10px 20px; /* Adjust padding inside the button */
  font-size: 18px; /* Increase font size for readability */
  border-radius: 5px; /* Rounded corners for the button */
  background-color: #1890ff; /* Primary color of Ant Design buttons */
  border: none; /* Remove default border */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Light shadow for the button */
  transition: background-color 0.3s, box-shadow 0.3s; /* Smooth hover effect */
}

.sign-up:hover {
  background-color: #40a9ff; /* Lighter blue on hover */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15); /* Slightly more pronounced shadow on hover */
}

.sign-up:focus {
  outline: none; /* Removes the default focus outline */
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2); /* Subtle focus ring */
}


/* Default: Desktop View */
.trial-text {
  display: flex;
  flex-direction: row; /* Keep in one row */
  justify-content: center;
  align-items: center;
  gap: 15px; /* Space between texts */
  font-size: 1.2rem;
  white-space: nowrap;
}

/* Adjustments for mobile screens */
/* For tablets and smaller devices */
/* For tablets and smaller devices (portrait mode on iPads) */
/* For tablets and larger mobile screens (portrait mode) */
@media (max-width: 768px) {
  .hero-section {
    padding: 10px;
    text-align: center;
  }

  .hero-title {
    font-size: 32px !important; /* Larger text for medium screens like iPhone 14 max */
    text-align: center;
    line-height: 1.2;
    padding: 0 10px;
  }

  .hero-description {
    font-size: 16px !important; /* Larger description text */
    text-align: center;
    padding: 0 10px;
  }

  .trial-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    font-size: 16px;
  }

  .subscription-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    width: 100%;
    padding-bottom: 1rem;
  }


  .sign-up {
    margin-top: 1rem;
  }
}

/* For smaller mobile screens (iPhone SE and smaller) */
@media (max-width: 480px) {
  .hero-title {
    font-size: 26px !important; /* Smaller title for small screens */
  }

  .hero-description {
    font-size: 16px !important; /* Smaller description for small screens */
  }

  .trial-text {
    font-size: 12px;
  }

  .sign-up {
    padding-bottom: 8px 20px;
  }

  .sign-up {
    margin-top: 1rem;
  }

/* For the columns */
.trial-column .ant-typography {
  font-size: 14px !important;
  text-align: center; /* Make text smaller in the columns for small screens */
}

.subscription-container {
  flex-direction: column; /* Stack items vertically */
  align-items: center;    /* Center the items */
  gap: 10px;              /* Slightly larger gap for better spacing */
  padding-bottom: 0.8rem; /* Adjust padding if necessary */
  width: 90%;             /* Make the width a little smaller to fit the screen */
}

.subscription-input,
  .subscribe-button {
    width: 100%;             /* Make the input fields and button take full width */
    height: 40px;            /* Increase height for easier tapping */
    font-size: 12px;         /* Adjust font size for readability */
    box-sizing: border-box; /* Ensure padding and borders are included in the width calculation */
  padding: 8px;         
  }
  .navbar .logo-text {
    font-size: 24px; /* Even smaller for mobile devices like iPhone SE */
  }
  /* VIDEO SECTION FIX */
  .video-section {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0;
  }

  .video-container {
    width: 90%;
    max-width: 100%;
display: flex;
justify-content: center; 
overflow: hidden;
margin: 0 auto; }

  .video-container video {
    width: 100%;
    max-width: 100%; /* Prevent video from becoming too large */
    height: auto;

object-fit: contain;  }
}


/* Subscribe Container */
/* Subscribe Container */
.subscribe-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 600px; /* Optional: Set a max-width for better alignment */
  margin: 0 auto; /* Center the container */
  padding: 20px; /* Optional: Add padding if needed */
  margin-top: 20px;
}

/* Subscribe Input */
.subscribe-input {
  width: 100%; /* Makes the input field take up the full width of its container */
  height: 50px; /* Set height to make input field and button the same height */
  padding: 0 14px; /* Remove top and bottom padding, only keep horizontal padding */
  font-size: 16px; /* Font size */
  border-radius: 5px; /* Rounded corners */
  border: 1px solid #d9d9d9; /* Light border color */
  box-sizing: border-box; /* Ensures padding is included in the width */
  margin-right: 10px; /* Space between the input and the button */
}

/* Subscribe Button */
.subscribe-button {
  width: 100%; /* Makes the button take up the full width of its container */
  height: 50px; /* Set height to match the input field */
  padding: 0 14px; /* Remove top and bottom padding, only keep horizontal padding */
  font-size: 16px; /* Font size */
  border-radius: 5px; /* Rounded corners */
  background-color: #1890ff; /* Button color */
  color: white; /* Text color */
  border: none; /* Removes default border */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Light shadow for the button */
  transition: background-color 0.3s, box-shadow 0.3s; /* Smooth hover effect */
}

/* Hover and Focus States for Subscribe Button */
.subscribe-button:hover {
  background-color: #40a9ff; /* Lighter blue when hovering */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15); /* Slightly more pronounced shadow on hover */
}

.subscribe-button:focus {
  outline: none; /* Removes default focus outline */
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2); /* Subtle focus ring */
}




/* src/App.css */
.site-layout-background {
  background: #fff;
}

.topbar {
  background-color: #001529;
  height: 60px;
  display: flex;
  align-items: center;
  padding: 0 20px;
  color: white;
}

.breadcrumb a {
  color: white !important;
  text-decoration: none;
}

.breadcrumb a:hover {
  color: #d1bd21; /* Adjust this to your desired hover color */
}

.ant-dropdown-menu {
  background-color: #001529; /* Adjust dropdown menu background if necessary */
}

.ant-dropdown-menu-item {
  color: white; /* Adjust dropdown menu item color */
}

.ant-dropdown-menu-item:hover {
  background-color: #d1bd21; /* Adjust dropdown menu item hover background color */
}

/* src/Components/FAQ.css */
.faq-section {
  padding: 40px 20px;
  margin-top: 10vh;
  background-color: #f0f2f5;
  color: #030303;
}

.faq-title {
  text-align: center;
  margin-bottom: 40px;
  color: #000000 !important;
}

.ant-collapse-header {
  font-size: 18px;
  font-weight: bold;
  color: #000000 !important;
}

.ant-collapse-content p {
  font-size: 16px;
  color: #000000;
}

.calendar-container {
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.calendar-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.calendar-table th,
.calendar-table td {
  border: 1px solid #ccc;
  padding: 10px;
  text-align: center;
  vertical-align: middle;
  height: 50px;
  width: 50px;
}

.calendar-table th {
  background-color: #f4f4f4;
  height: 50px;
  width: 50px;
}

.calendar-table td {
  cursor: pointer;
  user-select: none;
  height: 50px;
}

.calendar-table .available {
  background-color: lightgreen;
}

.calendar-table .reserved {
  background-color: #d1bd21;
}

.calendar-table .unavailable {
  background-color: rgb(208, 208, 208);
  opacity: 0.5;
  pointer-events: none;
}

.working-hours-selector {
  margin-top: 20px;
  display: flex;
  gap: 10px;
}

.working-hours-selector select {
  padding: 5px;
  font-size: 16px;
}

.working-hours-selector label {
  margin-right: 5px;
  font-weight: bold;
}

.working-hours-selector div {
  display: flex;
  align-items: center;
}
.month-dropdown {
  font-size: 1.2em;
  padding: 10px;
  border: 2px solid #007bff;
  border-radius: 5px;
  background-color: #f8f9fa;
  color: #007bff;
  outline: none;
  transition: all 0.3s ease;
}

.month-dropdown:hover {
  background-color: #e2e6ea;
}

.month-dropdown:focus {
  border-color: #0056b3;
}

.month-dropdown option {
  font-size: 1.2em;
  padding: 8px;
}

.days-grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 10px;
  margin-top: 20px;
}

.day {
  padding: 20px;
  text-align: center;
  cursor: pointer;
  font-size: 1.5em;
  border: 1px solid #ccc;
  border-radius: 4px;
  transition:
    background-color 0.3s,
    transform 0.3s;
}

.day.available {
  background-color: lightblue;
}

.day:hover {
  background-color: #add8e6;
  transform: scale(1.05);
}

.time-slots {
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(
    4,
    1fr
  ); /* Adjust number of columns as needed */
  gap: 10px;
}

.slot {
  padding: 10px;
  text-align: center;
  cursor: pointer;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.slot.available {
  background-color: lightgreen;
}

.working-days-selector {
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
}

.day-label {
  font-size: 1em;
  color: #007bff;
}

.day-label input {
  margin-right: 8px;
}
.working-hours-selector {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;
}

.hour-label {
  font-size: 0.9em;
  color: #007bff;
}

.hour-label input {
  margin-right: 8px;
}
.display-mode-selector {
  margin-top: 20px;
}

.display-mode-selector label {
  margin-right: 10px;
  font-weight: bold;
}

.display-mode-selector select {
  padding: 5px;
  font-size: 16px;
}
/* Footer.css */
.footer {
  background-color: #f0f2f5;
  padding: 20px 0;
  text-align: center;
}

.footer-section {
  margin: 20px;
}

.footer-section h4 {
  margin-bottom: 10px;
}

.footer-bottom {
  margin-top: 20px;
  border-top: 1px solid #e7e7e7;
  padding-top: 10px;
}

.popup {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup.show {
  display: flex;
}

.popup-inner {
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  text-align: left;
  width: 350px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: auto;
}

.popup-inner h2 {
  text-align: center; /* Center the "Contact Us" text */
  margin-bottom: 20px;
  color: #333;
}

.popup-inner form {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.popup-inner form div {
  margin-bottom: 10px; /* Reduced margin */
}

.popup-inner form label {
  margin-bottom: 5px;
  font-weight: bold;
  color: #555;
}

.popup-inner form input,
.popup-inner form textarea {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 100%;
  font-size: 14px;
  box-sizing: border-box;
}

.popup-inner form button {
  padding: 10px 20px;
  background: #1890ff;
  border: none;
  border-radius: 5px;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  transition: background 0.3s ease;
  margin-top: 10px; /* Added margin to separate from form */
}

.popup-inner form button:hover {
  background: #40a9ff;
}

.popup-inner .error {
  color: red;
  font-size: 14px;
  margin-top: 10px;
}

.popup-inner .close-button {
  padding: 10px 20px;
  background: #f5f5f5;
  border: none;
  border-radius: 5px;
  color: #333;
  font-size: 16px;
  cursor: pointer;
  transition: background 0.3s ease;
  margin: 20px auto 0; /* Center the button and add top margin */
  display: block; /* Make the button a block element */
}

.popup-inner .close-button:hover {
  background: #e5e5e5;
}
body {
  background-color: #f0f2f5; /* Update this to match your background color */
}

.header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 20px;
  height: 64px;
}

.logo {
  display: flex;
  align-items: center;
  margin-right: auto;
}

.logo-img {
  height: 50px; /* Adjust size as needed */
  width: auto;
  margin-top: 27px;
}

.profile-cards {
  display: flex;
  justify-content: space-around;
  width: 100%;
  max-width: 800px;
  margin-top: 50px;
}

.profile-card {
  width: 300px;
  text-align: center;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.profile-card h3 {
  margin-top: 10px;
}
.content {
  padding: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(
    100vh - 64px
  ); /* Adjust to ensure full height minus header */
  background-color: #f0f2f5; /* Update this to match your background color */
}

.section-divider {
  margin: 50px 0;
  border-top: 2px solid #ddd;
}

.pricing-section {
  background-color: #f0f2f5; /* Adjust to match your website's background color */
  padding: 50px 0;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
  text-align: center;
}

.section-title {
  font-size: 36px;
  margin-bottom: 30px;
  color: #333;
}

.pricing-card {
  border-radius: 15px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 100%;
  transition:
    transform 0.3s,
    box-shadow 0.3s;
  background-color: #5e676f; /* Dark background */
  color: #ffffff !important; /* Light text for contrast */
  padding: 20px;
}

.pricing-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.2);
}

/* Adjusting the color of the card title */
.pricing-card .ant-card-head-title {
  color: white;
  font-size: 25px; /* Set the title text color to white */
}

.card-header {
  font-size: 24px;
  font-weight: bold;
  color: #ffffff; /* Light text color */
  margin-bottom: 10px;
}

.plan-price {
  font-size: 20px;
  margin: 0px 0;
  color: #13396b;
  font-weight: bold;
}

.plan-price span {
  font-size: 16px;
  color: #a0a0a0;
}

.plan-features {
  list-style: none;
  padding: 0;
  margin: 20px 0;
  text-align: left;
  font-size: 16px;
  color: #e0e0e0; /* Light text color */
}

.plan-features li {
  margin-bottom: 10px;
  line-height: 1.5;
}

.plan-button {
  align-self: center;
}

.ant-card-body {
  height: 100%;
}
.card-body-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
}

.sale-price-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.sale-price-container .sale-price-badge {
  background: #d1bd21;
  color: black;
  padding: 4px 8px;
  border-radius: 5px;
  display: flex;
  gap: 5px;
}
.sale-price-container .sale-price-badge .actual-price {
  text-decoration: line-through;
}
.sale-price-container .sale-price-badge .percentage {
  font-weight: bold;
}

.section-divider {
  margin: 50px 0;
  border-top: 2px solid #ddd;
}

.image-text-section {
  padding: 10px 0;
  background-color: #f0f2f5; /* Adjust to match your website's background color */
  /* height: 600px; Increased height for better visibility */
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
}

.bordered-container {
  border: 10px solid #001529; /* Thick border */
  padding: 20px !important;
  background-color: #fff; /* White background to make the border stand out */
}
.container {
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 20px;
  height: 100%;
}

.image-container {
  text-align: center;
  height: 120%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.section-image {
  width: 100%;
  height: 100%;
  max-height: 120%; /* Ensuring image fits within the section */
  object-fit: cover;
  border-radius: 10px;
}

.text-container {
  padding: 20px;
  width: 70%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.section-title {
  font-size: 46px;
  margin-bottom: 20px;
  color: #333;
}

.section-description {
  font-size: 20px;
  color: #666;
  line-height: 1.5;
  text-align: center;
}

.section-description-2 {
  font-size: 20px;
  color: #666;
  line-height: 1.5;
  text-align: left;
}
@media (min-width: 576px) {
  .section-image {
    height: 400px; /* Adjust as needed */
  }
}

.content-row {
  height: 100%;
  display: flex;
  align-items: center;
}

.resume-upload-column {
  max-width: 150px;
  text-align: center;
  /* display: flex; */
  align-items: center;
}
.ant-upload-list-item-actions {
  display: none;
}

.text-editor .ql-toolbar {
  border-radius: 6px 6px 0 0;
}
.text-editor .ql-container {
  height: 200px;
  overflow: auto;
  border-radius: 0 0 6px 6px;
}



.video-section {
  text-align: center;
  margin-top: 30px;
  padding: 60px 0;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background: linear-gradient(to right, #FFE5B4, #A2C2E2); /* Lighter pastel colors */
}

.video-section::before {
  content: "";
  position: absolute;
  top: -20%;
  left: -20%;
  width: 140%;
  height: 140%;
  background: linear-gradient(45deg, rgba(255, 230, 183, 0.8), rgba(162, 194, 226, 0.8)); /* Softer, pastel gradient */
  border-radius: 50%;
  z-index: -1;
}
